import React, { useContext, useEffect } from 'react';
import axios from 'axios';

import BodyContainer from './BodyContainer.react';
import {
	BrowserRouter,
	Routes,
	Route,
  } from "react-router-dom";
import WEB_HOST from './util/webhost';
import { AppGlobalWrapper, GlobalContext } from './AppGlobalWrapper.react';
import SuccessPage from './components/success/SuccessPage.react';
import LegalPage from './legal/LegalPage.react';
import PrivacyPage from './legal/PrivacyPage.react';
import { PreviewURLContext } from './PreviewURLWrapper.react';
import SongLandingPage from './SongLandingPage.react';
import { init, track } from 'fbq'
import { Recommend } from '@mui/icons-material';
import RecommendedPage from './RecommendedPage.react'

const pixel = '458631315613018'

init(pixel)


const App = () => {
	const [globalContext, setGlobalContext] = useContext(GlobalContext);


	useEffect(()=>{
		console.log("Location", `https://${WEB_HOST}/api/stripe/create-payment-intent`)
		axios.post(`https://${WEB_HOST}/api/stripe/create-payment-intent`).then(response => {
				const data = response.data;
				console.log(data)
				setGlobalContext({...globalContext, stripeClientSecret: data.clientSecret})
			}).catch(error => {
				console.log(error)
			})
			track('PageView')
		}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<BodyContainer />}/>
				<Route path='/recommended' element={<RecommendedPage/>}/>
				<Route path="/song" element={<SongLandingPage/>}/>
				<Route path='/success' element={<SuccessPage />}/>
				<Route path='/legal' element={<LegalPage/>}/>
				<Route path='/privacy' element={<PrivacyPage/>}/>
			</Routes>
		</BrowserRouter>
	)
}

const AppWrapped = () => {
	return (
		<AppGlobalWrapper>
				<App />
		</AppGlobalWrapper>
	)
}


export default AppWrapped;
