import React, { useState, useCallback, useRef, useContext} from "react";
import { Box } from '@mui/system';

import { InputAdornment, Typography } from "@mui/material";
import "../static/css/SongSearchComponent.css";
import List from '@mui/material/List';


import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import axios from "axios";
import WEB_HOST from "../util/webhost";
import { GlobalContext } from "../AppGlobalWrapper.react";


const MobileSearchContainer = () => {
    const formik = useFormikContext();

    const [context, setContext] = useContext(GlobalContext);

    const [searchInput, setSearchInput] = useState(context.searchInput ?? '');
    const [searchData, setSearchData] = useState(context.searchData);

    const ref = useRef(null)

    const querySpotifyAPI = useCallback(
        _.throttle((searchInput) => {
            axios.get(`https://${WEB_HOST}/api/spotify/search-songs?q=${searchInput}`)
            .then(response => {
                setSearchData(response.data);
                setContext({
                    ...context,
                    searchInput: searchInput,
                    searchData: response.data,
                })
                ref.current.scrollTop = 0;
            })
            .catch(error => {
                console.log(error);
            });
        }, 500), [context]);

    const onSearchChange = event => {
        const input = event.target.value;
        setSearchInput(input);
        querySpotifyAPI(input);
    }

    return (
            <Box sx={{ padding: 2, position:'absolute', width: '100%', bottom:0, top:0, display:'flex', flexFlow:'column'}}>
                <div style={{flex: '0 1 auto', display:'flex', alignItems:'center'}}>

                <TextField
                    className="inputRounded"
                    fullWidth
                    id="campaignName"
                    type="text"
                    name="campaignName"
                    placeholder="Search Spotify songs..."
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => setSearchInput('')}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onBlur={() => {window.scrollTo({ top: 0, behavior: 'smooth' })}}
                    value={searchInput}
                    onChange={e => onSearchChange(e)}
                />
                </div>
                <List ref={ref} sx={{width: '100%', overflow: 'auto', marginY: 1, paddingRight: 2, flex: '1 1 auto'}}>
                {searchData && searchData.tracks && searchData.tracks.items.map(item => {
                    const onButtonClick = () => {
                        formik.setValues({
                            ...formik.values,
                            'spotifyID': item.id,
                            'campaignName': item.name + ' - ' + item.artists[0].name,
                        });
                    }

                    const selected = formik.values.spotifyID === item.id;

                    return (
                        <ListItemButton sx={{borderRadius: 6, marginTop: '5px'}} onClick={onButtonClick} selected={selected}>
                            <ListItemAvatar padding={3}>
                                <Avatar variant="square" src={item.album.images[0].url} />
                            </ListItemAvatar>
                            <ListItemText primary={<Typography>{item.name}</Typography>} secondary={<Typography style={{color:'white'}}>{item.artists[0].name}</Typography>} />
                        </ListItemButton>
                    );
                })}
                </List>
            </Box>
    );
}

export default MobileSearchContainer;
