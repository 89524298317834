import React from "react";
import { useFormikContext } from "formik";

import FormCard from '../form/FormCard.react';
import { Box } from "@mui/material";

export default function CostsCard({children, label}) {
    const formik = useFormikContext();

    let budget = parseFloat(formik.values.budget);;


    if (formik.values.budgetType === 'daily'){
        const timeDelta = (formik.values.endDate - formik.values.startDate) / 86400000;
        budget = budget * timeDelta;
    }

    const serviceFee = 1;
    const adjustedCost = Math.ceil((budget + serviceFee + 0.30) / 0.971 * 100) / 100;
    const processingFee = adjustedCost - budget - serviceFee;

    const totalPayment = budget + serviceFee + processingFee;

    return (
    <FormCard label="Review Cost">
     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
         <div>Budget:</div>
         <div>${budget.toFixed(2)}</div>
     </Box>
     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
         <div>Processing Fee:</div>
         <div>${processingFee.toFixed(2)}</div>
     </Box>
     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
         <div>Service Fee:</div>
         <div>${serviceFee.toFixed(2)}</div>
     </Box>
     <hr/>
     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
         <strong>Order Total</strong>
         <strong>${totalPayment.toFixed(2)}</strong>
     </Box>
     </FormCard>
    );
}
