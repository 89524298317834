import React, { useContext } from "react";

import MobileSearchContainer from "./MobileSearchContainer.react";
import { Box } from "@mui/system";
import SpotifyButton from "./SpotifyButton.react";
import { GlobalContext } from "../AppGlobalWrapper.react";


const MobileSelectPage = () => {

    const [context, setContext] = useContext(GlobalContext);
    const flexButton = (
        <Box paddingX={2} sx={{display: 'flex', justifyContent: 'center', width: '100%', position:'absolute', bottom: 0, bottom: 'calc(1rem + env(safe-area-inset-bottom))', transitions: 'bottom 2s'}}>
            <SpotifyButton fullWidth onClick={() => setContext({...context, page_index: ++context.page_index})} variant="contained">NEXT</SpotifyButton>
        </Box>
        )

    return (
        <div style={{minHeight:'400px',}}>
            <MobileSearchContainer/>
            {flexButton}
        </div>
    );
}

export default MobileSelectPage;
