import React, { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement
} from "@stripe/react-stripe-js";
import { useFormikContext, } from "formik";
import axios from 'axios';

import WEB_HOST from "../../util/webhost";
import { Box } from "@mui/material";
import { GlobalContext } from "../../AppGlobalWrapper.react";
import TermsAndConditionsCheckbox from "../../legal/TermsAndConditionsCheckbox.react";
import { Stack } from "@mui/system";
import STATIC_WEB_HOST from "../../util/StaticWebhost";

export default function CheckoutForm() {
  const [context, setContext] = useContext(GlobalContext);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormikContext();


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
     // Create Object that holds the information on what is being crated. Use formik information.
    // We need to track the inteded created advertisment here
    const clientSecret = context.stripeClientSecret;

    if (!clientSecret) {
      return;
    }

    const updateAd = async () => {
        await axios.post(`https://${WEB_HOST}/api/stripe/modify-ad`, {
        stripe_id: clientSecret,
        spotify_id: formik.values.spotifyID,
        start_date: formik.values.startDate,
        end_date: formik.values.endDate,
        instagram_placements: formik.values.instagramPlacements,
        budget_type: formik.values.budgetType,
        budget: formik.values.budget,
        campaign_name: formik.values.campaignName
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    updateAd();
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);


    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `https://${STATIC_WEB_HOST}/SUCCESS`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  return (
    // <FormCard>
    <Box style={{width:'100%', minHeight:'320px'}}>
      <form id="payment-form" onSubmit={handleSubmit} style={{display:'block', margin: 'auto'}}>

        <Stack spacing={1}>
        <LinkAuthenticationElement />
        <PaymentElement />
        <TermsAndConditionsCheckbox/>
        <button class='stripe-button' disabled={isLoading || !formik.values.termsAndConditions || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
        </Stack>

      </form>
      </Box>
      // </FormCard>
  );
}
