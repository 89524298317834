import React, { useContext } from "react";

import MobileHeader from './MobileHeader.react';
import MobileSelectPage from "./MobileSelectPage.react";
import MobileSetupPage from "./MobileSetupPage.react";
import MobileCheckoutPage from "./MobileCheckoutPage.react";
import MobileLandingPage from "./MobileLandingPage.react";

import { GlobalContext } from "../AppGlobalWrapper.react";

if(/Android/.test(navigator.appVersion)) {
    window.addEventListener("resize", function() {
      if(document.activeElement.tagName=="INPUT" || document.activeElement.tagName=="TEXTAREA") {
        document.activeElement.scrollIntoView();
      }
   })
 }

const BodyContainer = () => {

    const [context, setContext] = useContext(GlobalContext);

    const landingPage = <MobileLandingPage/>
    const songSelectPage = <MobileSelectPage/>
    const campaignSetupPage = <MobileSetupPage/>
    const checkoutPage = <MobileCheckoutPage/>

    const pageArray = [landingPage, songSelectPage, campaignSetupPage, checkoutPage];

    return (
        <div style={{position: 'absolute', left:0,right:0,top:0,bottom:0, background:'#191414', overflow: 'auto', display:'flex', flexFlow: 'column'}}>
            <div style={{flex: '0 1 auto'}}>
                {context.page_index != 0 && <MobileHeader/>}
            </div>
            <div style={{flex: '1 1 auto', position:'relative', width: '100%',}}>
                {pageArray[context.page_index]}
            </div>
        </div>
    );
}

export default BodyContainer;
