

import React from "react";

export default function OverflowCenterContainer({children}) {

    return (
        <div id='OverflowContainer'>
            <div id='OverflowWrapper'>
                {children}
            </div>
        </div>
    );
}
