import React from "react";
import TextField from "@mui/material/TextField";
import { useFormikContext, } from "formik";

import { DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers';

import { Alert, Stack } from "@mui/material";
import moment from "moment";

export default function EndDateInput() {
    const formik = useFormikContext();

    const handleChange = (value) => formik.setFieldValue('endDate', value)

    return (
        <Stack spacing={1}>
            <Stack direction='row' spacing={1} justifyContent='center'>
                <DatePicker
                    name="endDate"
                    id="endDate"
                    label="End Date"
                    minDate={formik.values.startDate}
                    onChange={(val) => handleChange(val.toDate())}
                    value={formik.values.endDate}
                    renderInput={(params) => (
                        <TextField sx={{width: '50%'}} {...params} helperText={params?.inputProps?.placeholder} variant='outlined'/>
                    )}
                    disablePast
                />
                <TimePicker
                    label="Time"
                    value={formik.values.endDate}
                    sx={{width: '400px'}}
                    onChange={(val) => handleChange(val.toDate())}
                    // minTime={formik.values.startDate}
                    minTime={moment(formik.values.startDate)}
                    disableIgnoringDatePartForTimeValidation={true}
                    renderInput={(params) => <TextField sx={{width: '50%'}} {...params} variant='outlined'/>}
                />
            </Stack>
            {formik.errors.endDate && <Alert variant='filled' severity="error">End Date cannot be before the Start Date</Alert>}
        </Stack>

    );
}
