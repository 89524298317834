import React, { useState, createContext} from "react";

export const GlobalContext = createContext();

export const AppGlobalWrapper = ({children}) => {
    const INITIAL_STATE = {
        stripeClientSecret: null,
        page_index: 0,
        formPageIndex: 0,
        desktop_index: 0,
        previewURL_map: new Map(),
        open_terms_and_conditions: false,
    }

    const [context, setContext] = useState(INITIAL_STATE);

    return (
        <GlobalContext.Provider value={[context, setContext]}>
            {children}
        </GlobalContext.Provider>
    );
}
