import React, { useCallback, useContext } from "react";
import { Box } from '@mui/system';
import { GlobalContext } from "../AppGlobalWrapper.react";
import DesktopHeader from "./DesktopHeader.react";
import DesktopFooter from "./DesktopFooter.react";
import LandingPageContent from "../components/landing/LandingPageContent.react";
import DesktopSetupPage from "./DesktopSetupPage.react";
import DesktopCheckoutPage from "./DesktopCheckoutPage.react";

const DesktopContainer = () => {
    const [context, setContext] = useContext(GlobalContext);

    const setDesktopIndex = useCallback((index) => setContext({...context, desktop_index: index}), [context]);

    const LandingPage = (
        <Box sx={{diplay:'block', margin:'auto'}}>
            <LandingPageContent handelGetStarted={() => setDesktopIndex(1)}/>
        </Box>
    )

    const CustomizePage = (
        <Box sx={{width:'100%', height:'100%'}}>
        <DesktopSetupPage/>

        </Box>
    )

    const CheckoutPage = (
        <Box sx={{width:'100%', height:'100%'}}>

        <DesktopCheckoutPage />
        </Box>
    )

    const DesktopBodyArray = [LandingPage, CustomizePage, CheckoutPage]

    return (
        <Box sx={{position:'absolute', width: '100%', bottom:0, top:0, display:'flex', flexFlow:'column', overflow:'hidden', justifyContent:'center', alignItems:'center'}}>
            <Box sx={{position: 'absolute', top:'0px', width:'100%', height:'90px'}}>
                <DesktopHeader/>
            </Box>
            <Box sx={{position:'absolute', top:'90px', bottom:'30px', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                {DesktopBodyArray[context.desktop_index]}
            </Box>
            <Box sx={{position: 'absolute', bottom:0, width:'100%', height:'30px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <DesktopFooter/>
            </Box>
        </Box>
);
}

export default DesktopContainer;
