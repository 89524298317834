import React from "react";
import { useFormikContext, } from "formik";
import { Box, Stack } from "@mui/material";

const MonthStyle = {
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
    color: 'red',

}
const DateStyle = {
    fontWeight: 'normal',
    fontSize: '24px',
    textAlign: 'center',

}
const WeekdayStyle = {
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center'
}

const TimeStyle = {
    fontWeight: 'normal',
    fontSize: '18px',
    textAlign: 'center'
}

const BoxTitle = {
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center'
}


export default function DateReviewComponent(datetime) {
    const formik = useFormikContext();

    const startDate = formik.values.startDate;
    const endDate = formik.values.endDate;

    const start = {
        month: startDate.toLocaleDateString('en-us', {month: 'short'}).toUpperCase(),
        day: startDate.toLocaleDateString('en-us', {day: 'numeric'}),
        weekday:  startDate.toLocaleDateString('en-us', {weekday: 'long'}),
        time: startDate.toLocaleTimeString('en-us', {hour: 'numeric', minute:'2-digit'})

    }

    const end = {
        month: endDate.toLocaleDateString('en-us', {month: 'short'}).toUpperCase(),
        day: endDate.toLocaleDateString('en-us', {day: 'numeric'}),
        weekday:  endDate.toLocaleDateString('en-us', {weekday: 'long'}),
        time: endDate.toLocaleTimeString('en-us', {hour: 'numeric', minute:'2-digit'})
    }

    const DateComponet = (date) => {
        return (
            <Box sx={{
                minWidth: 50,
                height: 60,
                borderRadius: '8px',
                padding: '5px',
            }}>
                <Stack alignContent='center' justifyContent="center">
                    <div style={MonthStyle}>{date.month}</div>
                    <div style={DateStyle}>{date.day}</div>
                    <div style={WeekdayStyle}>{date.weekday}</div>
                </Stack>
            </Box>
        );
    }

    const TimeComponet = (date, start = true) => {
        return (
            <Stack padding='10px' spacing={1}>
                <div style={BoxTitle}>{start ? 'Start Time' : 'End Time'}</div>
                <div style={TimeStyle}>{date.time}</div>
            </Stack>
        );
    }

    const startDateComponent = (
        <div style={{display:'flex'}}>
            <div>{DateComponet(start)}</div>
            <div>{TimeComponet(start, true)}</div>
        </div>
    )

    const endDateComponent = (
        <div style={{display:'flex'}}>
            <div>{DateComponet(end)}</div>
            <div>{TimeComponet(end, false)}</div>
        </div>
    )

    return (
        <Box sx={{
            borderRadius: '8px',
            display: 'flex',
        }}>
            {startDateComponent}
            {endDateComponent}
        </Box>
    );
}
