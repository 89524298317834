import React from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFormikContext, } from "formik";
import { FormControl } from "@mui/material";


export default function BudgetTypeInput() {
    const formik = useFormikContext();

    return (
        <FormControl sx={{minWidth: 180, width:'50%', input:{color:'white'}}}>
        <Select
            fullWidth
            name='budgetType'
            id="budgetType"
            value={formik.values.budgetType}
            onChange={e => formik.setFieldValue('budgetType', e.target.value)}
            variant="outlined"
            onBlur={formik.handleBlur}
        >
            <MenuItem value='lifetime'>Lifetime Budget</MenuItem>
            <MenuItem value='daily' >Daily Budget</MenuItem>
        </Select>
        </FormControl>
    );
}
