/*
 * Bryce Watson Spotify Card - Ads Manger Setup
 * Nov 2022
 */

import React, { useCallback, useContext } from "react";
import { useFormikContext } from "formik";

import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, useMediaQuery, useTheme } from "@mui/material";
import { GlobalContext } from "../AppGlobalWrapper.react";


export default function TermsAndConditionsCheckbox() {
    const formik = useFormikContext();
    const [context, setContext] = useContext(GlobalContext);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));


    const setTermsAndConditions = useCallback((value) => {setContext({...context, open_terms_and_conditions: value})},[context])

    const onClick = (event) => {
        event.preventDefault();
        if (smallScreen) {
          window.open('/legal', '_blank');
        } else {
          setTermsAndConditions(true);
        }
      }

    return (
        <FormControlLabel
        control={<Checkbox />}
        label={<div>I have read and agree to the <u onClick={onClick}>terms and conditions.</u></div>}
        checked={formik.values.termsAndConditions}
        onChange={() =>
            formik.setFieldValue(
            'termsAndConditions',
            !formik.values.termsAndConditions,
            )
        }
        />
    );
}
