import React, { useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm.react";

import "../../static/css/Stripe.css";
import { GlobalContext } from "../../AppGlobalWrapper.react";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_live_51LcuYVBOH3g6Ua9e6MGS7OxNg2xFFhxadToLNYMvmSkoVgjEn4FoZK9hJ5TPOWCITvFApCbl89gPjBC5fs203IeA00lhKCDwka");

export default function App() {
  const [globalContext, _] = useContext(GlobalContext)

  const clientSecret = globalContext.stripeClientSecret;

  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#0570de',
    }
  };
  const options = {
    clientSecret,
    appearance,
    loader:'never'
  };

  console.log(clientSecret)

  return (
      clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )
  );
}
