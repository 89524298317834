import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";

import { useFormikContext } from "formik";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import InputAdornment from '@mui/material/InputAdornment';
import { Stack } from "@mui/system";
import { Alert } from "@mui/material";

export default function CampaignNameCard({children, label}) {
    const formik = useFormikContext();
    const [focused, setFocused] = useState(false);

    const displayError = formik.touched.campaignName && formik.errors.campaignName && !focused;

    return (
        <Stack spacing={1}>
         <TextField
            fullWidth
            id="campaignName"
            type="text"
            name="campaignName"
            placeholder="Campaign Name"
            onFocus={()=>setFocused(true)}
            onBlur={()=>setFocused(false)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <MusicNoteIcon color='info'/>
                    </InputAdornment>
                ),
            }}
            value={formik.values.campaignName}
            onChange={formik.handleChange}
            error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
            helperText={formik.touched.campaignName && formik.errors.campaignName}
            variant="outlined"/>
            {displayError && <Alert variant='filled' severity="error">Campaign Name is a required field.</Alert>}
        </Stack>
    );
}
