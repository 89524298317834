import './static/css/App.css';
import React, { useEffect, useState } from 'react';
import { Box, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Stack, Grid } from '@mui/material';

import SpotifyButtonSmall from "./mobile/SpotifyButtonSmall.react";
import AmazonButtonSmall from './mobile/AmazonButtonSmall.react';
import WEB_HOST from './util/webhost';
import axios from 'axios';
import SpotifyButton from './mobile/SpotifyButton.react';
import DesktopFooter from './desktop/DesktopFooter.react';


function RecommmendListItem({ img, artist_name, song_name, amazon_link, spotify_link }) {
    return (
        <Box sx={{ width: '100%' }} display='flex' justifyContent='space-between' alignItems='top'>
            <Stack direction='row' spacing={1}>
                <Avatar sx={{ width: '80px', height: '80px' }} variant="square" src={img} />
                <Stack spacing={1} direction='column'>
                    <div>{song_name}</div>
                    <div>{artist_name}</div>
                </Stack>
                {/* <ListItemText primary={song_name} secondary={artist_name}/> */}
            </Stack>
            <Stack spacing={1}>
                <SpotifyButtonSmall style={{ minWidth: '150px' }} variant='contained' onClick={() => { window.location = spotify_link }}>Listen On Spotify</SpotifyButtonSmall>
                <AmazonButtonSmall style={{ minWidth: '150px' }} variant='contained' onClick={() => { window.location = amazon_link }}>See on Amazon</AmazonButtonSmall>
            </Stack>
        </Box>

    );
}


function RecommendedPage() {

    const [searchData, setSearchData] = useState([]);

    const navigate = (url) => { window.location = url; }

    useEffect(() => {
        axios.get(`https://${WEB_HOST}/api/spotify/recommended`)
            .then(response => {
                console.log(response);
                console.log('response.data', response.data)
                setSearchData(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    if (searchData.length == 0) {
        return null;
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            padding={2}
            style={{ background: '#332e2e', color: 'white' }}
        >
            <Grid item>
                <Stack spacing={2}>
                    <SpotifyButton fullWidth>Promocards.io</SpotifyButton>
                    {/* <div>New Music</div> */}

                    <Grid item xs={12}>

                        <Grid container spacing={2} justifyContent='center' marginY={4}>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={2}>
                                    <img src={searchData[0].album.images[0].url} width='200px'></img>
                                    <Stack spacing={1}>
                                        <h1>{searchData[0].name}</h1>
                                        <h2>{searchData[0].artists[0].name}</h2>
                                    </Stack>

                                </Stack>


                            </Grid>
                            <Grid item xs={12}><SpotifyButtonSmall onClick={() => navigate(searchData[0].external_urls.spotify)} fullWidth variant='contained'>Listen On Spotify</SpotifyButtonSmall></Grid>
                            <Grid item xs={12}><AmazonButtonSmall onClick={() => navigate(searchData[0].amazon_link)} fullWidth variant='contained'>See on Amazon</AmazonButtonSmall></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}><h3>More New Songs</h3> </Grid>
                    <Grid item xs={12}>
                    <Stack spacing={4}>
                        {searchData.map((item, index) => {
                            return (
                                <RecommmendListItem
                                    img={item.album.images[0].url}
                                    artist_name={item.artists[0].name}
                                    song_name={item.name}
                                    amazon_link={item.amazon_link}
                                    spotify_link={item.external_urls.spotify}
                                />
                            );
                        })}
                    </Stack>
                    </Grid>

                    <Grid item xs={12}>
                    <div style={{ "border": '1px solid white', padding: 4 }}>This website is part of the Amazon affilate program and any purchaseds you make from the recomendations of Promocards.io will directly benfit the site.</div>
                    </Grid>
                    <DesktopFooter />
                </Stack>

            </Grid>
        </Grid>
    );
}

export default RecommendedPage;
