/*
 * Bryce Watson
 * Spotify - Instagram Advertising Project
 * September 2022
 */

import { Formik } from 'formik';
import * as Yup from 'yup';

 const AdFormWrapper = ({children}) => {

    const FormSchema = Yup.object().shape({
        campaignName: Yup.string('Campaign Name').required('Campaign name is required'),
        startDate: Yup.date().required('Start date is required'),
        endDate: Yup.date().required('End date is required').min(Yup.ref('startDate', "End date is before start date")),
        budget: Yup.number(1).required('Budget is required').when('budgetType', {is: 'daily', then: Yup.number().min(1)}),
        instagramPlacements: Yup.array().min(1).required("Include one placement"),
        budgetType: Yup.string().required(),
        termsAndConditions: Yup.boolean(),
    })

    const initEndDate = new Date();
    initEndDate.setDate(initEndDate.getDate() + 7);
    initEndDate.setHours(12,0,0,0);

    const initStartDate = new Date();
    initStartDate.setHours(12,0,0,0);

    const initValues = {
        campaignName: 'Old Time Rock & Roll - Bob Seger',
        startDate: initStartDate,
        endDate: initEndDate,
        budget: '10.00',
        instagramPlacements: ['instagram-feed', 'instagram-stories', 'instagram-reels',],
        budgetType: 'daily',
        spotifyID: null,
        termsAndConditions: false,
    }

    const handleSubmit = (values) => {console.log(values)};

    return (
        <Formik initialValues={initValues} validationSchema={FormSchema} onSubmit={handleSubmit}>
            {children}
        </Formik>
    )
}

export default AdFormWrapper
