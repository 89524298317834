import React, { useContext, useEffect, useState, } from "react";
import { useFormikContext, } from "formik";
import { Skeleton } from "@mui/material";

import WEB_HOST from "../util/webhost";
import axios from "axios";
import { GlobalContext } from "../AppGlobalWrapper.react";
import { Box } from "@mui/system";
import Spotify_Cards_Webhost from "../util/SpotifyCardsWebhost";


var startTime, endTime;

function start() {
  startTime = new Date();
};

function end() {
  endTime = new Date();
  var timeDiff = endTime - startTime; //in ms
  // strip the ms
  timeDiff /= 1000;

  // get seconds
  var seconds = Math.round(timeDiff);
  return seconds;
}

export default function MobileAdPreview(maxHeight) {
    const [context, setContext] = useContext(GlobalContext);

    const formik = useFormikContext();
    const [expanded, setExpanded] = useState(false);
    const [imgLoaded, setImageLoaded] = useState(false);

    const mySpotifyID = formik.values.spotifyID ?? '5EOoMWIB9iK4ZpcSex9Ec7';

    const [iframeURLs, setIframeURLs] = useState(null);
    const [countIframesLoaded, setCountIframesLoaded] = useState(0);


    useEffect(() => {
        setCountIframesLoaded(0);
        start();
        axios.get(`https://${WEB_HOST}/api/facebook/preview?spotifyID=${mySpotifyID}`)
            .then(response => {
                setIframeURLs(response.data)
                end();
            })
            .catch(error => {
                console.log(error);
            });
    }, [mySpotifyID]);



    let instagramStory = iframeURLs && (
        <div id='instagramReelsWrap' style={{display: formik.values.instagramPlacements.includes('instagram-stories') ? 'block' : 'none'}}>
            <iframe
                id='instagramReelsFrame'
                src={iframeURLs[1]}
                width='320px'
                height='567px'
                scrolling="no"
                frameborder={0}
                onLoad={() => {end(); setCountIframesLoaded(countIframesLoaded + 1)}}
                style={{
                    border: 'none'
                }}></iframe>
        </div>
    );

    let instagramReels = iframeURLs && (
        <div id='instagramReelsWrap' style={{display: formik.values.instagramPlacements.includes('instagram-reels') ? 'block' : 'none'}}>
            <iframe
                id='instagramReelsFrame'
                src={iframeURLs[2]}
                width='320px'
                height='567px'
                scrolling="no"
                frameborder={0}
                onLoad={() => {end(); setCountIframesLoaded(countIframesLoaded + 1)}}
                style={{
                    border: 'none'
                }}></iframe>
        </div>
    )

    let instagramFeed = iframeURLs &&  (
        <div id='instagramFeedWrap' style={{display: formik.values.instagramPlacements.includes('instagram-feed') ? 'block' : 'none'}}>
            <iframe
                id='instagramFeedFrame'
                src={iframeURLs[0]}
                width='320px'
                height='567px'
                scrolling="no"
                frameborder={0}
                onLoad={() => {end(); setCountIframesLoaded(countIframesLoaded + 1)}}
                style={{
                    border: 'none'
                }}></iframe>
        </div>
    )


    const InstagramPreviews = (
        <div style={{display: context.page_index == 3 ? 'block' : 'none'}}>
            <div id='loadingDiv' style={{display: countIframesLoaded < 3 ? 'flex' : 'none', alignContent: 'center', justifyContent:'space-evenly'}}>
                {formik.values.instagramPlacements.includes('instagram-feed')  && <div><Skeleton variant="rectangular" width={120} height={213} /></div>}
                {formik.values.instagramPlacements.includes('instagram-stories') && <div><Skeleton variant="rectangular" width={120} height={213} /></div>}
                {formik.values.instagramPlacements.includes('instagram-reels') && <div><Skeleton variant="rectangular" width={120} height={213} /></div>}
            </div>
            <div style={{display: countIframesLoaded >= 3 ? 'flex' : 'none', alignContent: 'center', justifyContent:'space-evenly'}}>
                {instagramFeed}
                {instagramReels}
                {instagramStory}
            </div>
        </div>
    )

    return (
        <Box style={{backgroundColor: '#282828', display: context.hidePreview ? 'none' : 'block'}} padding={1}>
            <div style={{display: context.page_index == 3 ? 'none' : 'block' }}>
                <img src={`https://${Spotify_Cards_Webhost}/api?id=${mySpotifyID}&orientation=portrait`}
                    alt='noImg'
                    style={{ maxHeight: expanded ? '400px' : '200px', display: imgLoaded ? 'block' : 'none', margin: 'auto'}}
                    onClick={() => { setExpanded(!expanded) }}
                    onLoad={() => setImageLoaded(true)}/>
                <div style={{display: imgLoaded ? 'none' : 'block', margin: 'auto', width:'120px'}}>
                    <Skeleton variant="rectangular" width={120} height={200} margin='auto'/>
                </div>
                {/* <img src={BobSeager}
                    style={{ maxHeight: expanded ? '400px' : '200px', display: imgLoaded ? 'none' : 'block', margin: 'auto'}}
                    onClick={() => { setExpanded(!expanded) }}/> */}
            </div>

            {InstagramPreviews}
        </Box>

    );
}
