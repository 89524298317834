import React, { useCallback, useContext, useState } from "react";
import { Stack } from '@mui/system';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, useMediaQuery, useTheme } from "@mui/material";
import TermsAndConditonsHTML from "../legal/TermsAndConditionsHTML.react";
import PrivacyHTML from "../legal/PrivacyHTML.react";
import { GlobalContext } from "../AppGlobalWrapper.react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };

const DesktopFooter = () => {
    const [context, setContext] = useContext(GlobalContext);
    const [FAQ, setFAQ] = useState(false);
    const [contactUs, setContactUs] = useState(false);
    const [privacy, setPrivacy] = useState(false)

    const termsAndConditions = context.open_terms_and_conditions;
    const setTermsAndConditions = useCallback((value) => {setContext({...context, open_terms_and_conditions: value})},[context])

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const FAQModal = (
      <Dialog
        open={FAQ}
        onClose={()=>setFAQ(false)}
        scroll={'paper'}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle id="scroll-dialog-title">Frequently Asked Questions</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            tabIndex={-1}
          >
            <div>
              <p><strong>1. Who will see my Ads?</strong></p>
              <p>The current audience is chosen to be the set of all United States Instagram users.</p>
              <p><strong>2. What account will my ad run on?</strong></p>
              <p>All advertisements are run on our Spotify Upcoming Instagram account.</p>
              <p><strong>3. Where can I see my advertisement's performance data?</strong></p>
              <p>We will send each advertiser an email follow the completion of your purchased ad campaign. This email will detail the performace of your ad using data recieved from Facebook Advertising Metrics.</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setFAQ(false)}>Close</Button>
        </DialogActions>
      </Dialog>);

    const ContactUs = (
      <Dialog
        open={contactUs}
        onClose={()=>setContactUs(false)}
        scroll={'paper'}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle id="scroll-dialog-title">Contact Us</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            tabIndex={-1}
          >
            <div>
              <p>Email: lightweightadvertising@gmail.com</p>
              <p>Customer Support: (810) 623-6283</p>
              <p>Plase contanct us with any support you may need. We're just getting started with this product and would be happy to recieve feedback and devlope for you needs.</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setContactUs(false)}>Close</Button>
        </DialogActions>
      </Dialog>);

        const TermsAndConditons = (
          <Dialog
            open={termsAndConditions}
            onClose={()=>setTermsAndConditions(false)}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth={'md'}
          >
            <DialogTitle id="scroll-dialog-title">Terms And Conditions</DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
              >
                <div id='#BRYCE'>
                  <TermsAndConditonsHTML/>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>setTermsAndConditions(false)}>Close</Button>
            </DialogActions>
          </Dialog>)


const Privacy = (
  <Dialog
    open={privacy}
    onClose={()=>setPrivacy(false)}
    scroll={'paper'}
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
    fullWidth
    maxWidth={'md'}
  >
    <DialogTitle id="scroll-dialog-title">Privacy Notice</DialogTitle>
    <DialogContent dividers={true}>
      <DialogContentText
        id="scroll-dialog-description"
        tabIndex={-1}
      >
        <div id="BRYCE">
          <PrivacyHTML/>
        </div>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={()=>setPrivacy(false)}>Close</Button>
    </DialogActions>
  </Dialog>)

  const onClickLegal = () => {
    if (smallScreen) {
      window.open('/legal', '_blank');
    } else {
      setTermsAndConditions(true);
    }
  }

  const onClickPrivacy = () => {
    if (smallScreen) {
      window.open('/privacy', '_blank');
    } else {
      setPrivacy(true);
    }
  }


    return (
    <>
        {FAQModal}
        {ContactUs}
        {TermsAndConditons}
        {Privacy}
        <Box direction={'row'} alignItems={'center'} justifyContent={'space-evenly'} sx={{marginX:'auto', maxWidth:'600px', height:'50px', width:'100%', display:'flex', color:'white', fontSize:'11px', cursor: 'default'}}>
            <div style={{cursor:'pointer'}} onClick={() => setFAQ(true)}>FAQ</div>
            <div style={{cursor:'pointer'}} onClick={onClickLegal}>LEGAL</div>
            <div style={{cursor:'pointer'}} onClick={onClickPrivacy}>PRIVACY</div>
            <div>&copy; LIGHTWEIGHT ADS</div>
            <div>ENGLISH</div>
            <div style={{cursor:'pointer'}} onClick={() => setContactUs(true)}>CONTACT US</div>
        </Box>
    </>

);
}

export default DesktopFooter;
