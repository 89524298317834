/*
 * Bryce Watson
 * Spotify - Instagram Advertising Project
 * September 2022
 */

import React from "react";

import InstagramPlacementsCard from './InstagramPlacementsCard.react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import MobileCampaignNameInput from '../../mobile/MobileCampaignNameInput.react';
import MobileScheduleInput from '../../mobile/MobileScheduleInput.react';
import BudgetInput from './BudgetInput.react';

const AdFormV4 = () => {
     return (
        <Stepper orientation="vertical" nonLinear={true}>
            <Step active key={'Campaign Name'}>
                <StepLabel>Campaign Name</StepLabel>
                <StepContent>
                    <MobileCampaignNameInput />
                </StepContent>
            </Step>
            <Step active key={"Budget"}>
                <StepLabel>Budget</StepLabel>
                <StepContent marginTop={1}>
                    <BudgetInput />
                </StepContent>
            </Step>
            <Step active key={"Schedule"}>
                <StepLabel>Schedule</StepLabel>
                <StepContent>
                    <MobileScheduleInput />
                </StepContent>
            </Step>

            <Step active key={"Instagram Placements"}>
                <StepLabel>Instagram Placements</StepLabel>
                <StepContent>
                    <InstagramPlacementsCard />
                </StepContent>
            </Step>
        </Stepper>
    );
}

export default AdFormV4;
