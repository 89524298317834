import '../static/css/App.css';
import React from 'react';
import PrivacyHTML from './PrivacyHTML.react';
import { Box } from '@mui/material';

function PrivacyPage() {
  return (
	<Box paddingX={4} sx={{maxWidth:'800px', margin:'auto'}}>
      <PrivacyHTML />
    </Box>

  );
}

export default PrivacyPage;
