import React, { useContext, useCallback } from "react";
import { Box } from '@mui/system';

import { Grid } from "@mui/material";
import ImageContainer from '../components/preview/ImageContainer.react';

import MobileSearchContainer from "../mobile/MobileSearchContainer.react";
import SpotifyButton from "../mobile/SpotifyButton.react";
import { GlobalContext } from "../AppGlobalWrapper.react";
import AdFormV4 from "../components/form/AdFormV4.react";
import OverflowCenterContainer from "../util/OverflowCenterContainer.react";
import { useFormikContext } from "formik";


const DesktopSetupPage = () => {
    const [context, setContext] = useContext(GlobalContext);
    const formik = useFormikContext();

    const setDesktopIndex = useCallback((index) => setContext({...context, desktop_index: index}), [context]);

    return (
        <Box sx={{position:'relative', width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <Box sx={{position:'absolute', top:'0px', bottom:'55px', width:'100%'}}>
            <Grid container sx={{height:'100%', width:'100%'}} paddingX={4}>
                <Grid item xs={4} alignItems={'center'} sx={{position:'relative', height:'100%'}}>
                    <MobileSearchContainer/>
                </Grid>
                <Grid item xs={4} sx={{position:'relative', height:'100%'}} paddingX={2}>
                    <OverflowCenterContainer>
                        <AdFormV4/>
                    </OverflowCenterContainer>
                </Grid>
                <Grid item xs={4} sx={{position:'relative', height:'100%'}}>
                    <OverflowCenterContainer>
                        <ImageContainer/>
                    </OverflowCenterContainer>
                </Grid>
            </Grid>
            </Box>
            <Grid container sx={{position:'absolute', bottom:'0px', height:'50px', width:'100%', display:'flex', alignContent:'center', justifyContent:'center'}}>
                <Grid item xs={4}>
                    <SpotifyButton disabled={!formik.isValid} fullWidth onClick={() => setDesktopIndex(2)}>NEXT</SpotifyButton>
                </Grid>
            </Grid>
        </Box>

        );
}

export default DesktopSetupPage;
