import React from "react";
import { Box } from '@mui/system';


export default function FormCard({children, label, button}) {
    return (
        <Box sx={{p: 1}}>
            {label && <Box sx={{mb: 1, fontWeight: 'bold', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                {label}
                {button}
            </Box>}
            {children}
        </Box>
    );
}
