import { Box } from "@mui/system";
import React, { useCallback, useContext } from "react";

import { GlobalContext } from "../AppGlobalWrapper.react";
import LandingPageContent from "../components/landing/LandingPageContent.react";
import DesktopFooter from "../desktop/DesktopFooter.react";

const MobileLandingPage = () => {

    const [context, setContext] = useContext(GlobalContext);

    const setMobileIndex = useCallback(() => setContext({ ...context, page_index: 1 }), [context]);

    return (
        <>
        <LandingPageContent handelGetStarted={() => setMobileIndex()}/>
        <Box sx={{position:'absolute', bottom:0, width:'100%', margin:'auto'}}>
            <DesktopFooter/>
        </Box>
        </>
    );
}

export default MobileLandingPage;
