/*
 * Bryce Watson
 * Spotify - Instagram Advertising Project
 * September 2022
 */

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useFormikContext, } from "formik";

import BudgetTypeInput from './BudgetTypeInput.react';
import InputAdornment from '@mui/material/InputAdornment';


import { Alert, Stack } from "@mui/material";

export default function EndDateInput() {
    const formik = useFormikContext();

    const [focused, setFocused] = useState(false);

    const displayError = Boolean(formik.errors.budget) && !focused;

    return (
        <Stack spacing={1}>
            <Stack direction='row' spacing={1}>
                <BudgetTypeInput/>
                <TextField
                    id="budget"
                    type="number"
                    pattern="d\*"
                    name="budget"
                    label={formik.values.budgetType == 'lifetime' ? "Lifetime Budget" : "Daily Budget"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                    value={formik.values.budget}
                    onFocus={()=>setFocused(true)}
                    onChange={formik.handleChange}
                    onBlur={() => {
                        setFocused(false)
                        formik.setFieldValue('budget', parseFloat(formik.values.budget).toFixed(2))}}
                    error={displayError}
                    variant='outlined'
                    sx={{ input: { color: "#ffff" }, width:'50%'}}
                />
            </Stack>
            {displayError && <Alert variant='filled' severity="error">Minimunn Daily Budget must be at least $1.00</Alert>}
        </Stack>

    );
}
