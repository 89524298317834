import React, { useCallback, useContext, useEffect, useState, } from "react";
import { useFormikContext, } from "formik";
import { Skeleton } from "@mui/material";

import { Stack } from "@mui/material";
import WEB_HOST from "../../util/webhost";
import axios from "axios";
import { GlobalContext } from "../../AppGlobalWrapper.react";
import { Box } from "@mui/system";
import SpotifyButton from '../../mobile/SpotifyButton.react';
import { PreviewURLContext } from "../../PreviewURLWrapper.react";


export default function AdPreview() {
    const [context, setContext] = useContext(PreviewURLContext);

    const formik = useFormikContext();

    const mySpotifyID = formik.values.spotifyID ?? '5EOoMWIB9iK4ZpcSex9Ec7';

    const iframeURLs = context.previewURL_map.get(mySpotifyID);
    const [countIframesLoaded, setCountIframesLoaded] = useState(0);

    const [previewIndex, setPreviewIndex] = useState(0);

    useEffect(()=>{
        const defaultIndex = formik.values.instagramPlacements.includes('instagram-stories') ? 0
            : formik.values.instagramPlacements.includes('instagram-reels') ? 2 : 1;
        setPreviewIndex(defaultIndex)

    },[formik])


    useEffect(() => {
        if (iframeURLs != null){
            return;
        }

        setCountIframesLoaded(0);

        axios.get(`http://${WEB_HOST}/api/facebook/preview?spotifyID=${mySpotifyID}`)
            .then(response => {
                const map = new Map(context.previewURL_map).set(mySpotifyID, response.data);
                setContext({...context, previewURL_map: map})
                console.log(response.data)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);



    let instagramStory = iframeURLs && (
        <div  id='desktopInstagramReelsWrap' style={{display: previewIndex == 0 ? 'block' : 'none'}}>
  {/* <div id='desktopInstagramReelsWrap' style={{display: formik.values.instagramPlacements.includes('instagram-stories') ? 'block' : 'none'}}> */}
  <iframe
                id='desktopInstagramReelsFrame'
                src={iframeURLs[1]}
                width='320px'
                height='567px'
                scrolling="no"
                border='1px solid white'
                onLoad={() => setCountIframesLoaded(countIframesLoaded + 1)}
                style={{
                    border: 'none'
                }}></iframe>
        </div>
    );

    let instagramReels = iframeURLs && (
  <div  id='desktopInstagramReelsWrap' style={{display: previewIndex == 2 ? 'block' : 'none'}}>

  {/* <div id='desktopInstagramReelsWrap' style={{display: formik.values.instagramPlacements.includes('instagram-reels') ? 'block' : 'none'}}> */}
  <iframe
                id='desktopInstagramReelsFrame'
                src={iframeURLs[2]}
                width='320px'
                height='567px'
                scrolling="no"
                frameborder={0}
                onLoad={() => setCountIframesLoaded(countIframesLoaded + 1)}
                style={{
                    border: 'none'
                }}></iframe>
        </div>
    )

    let instagramFeed = iframeURLs &&  (
        <div  id='desktopInstagramFeedWrap' style={{display: previewIndex == 1 ? 'block' : 'none'}}>
        {/* // <div id='desktopInstagramReelsWrap' style={{display: formik.values.instagramPlacements.includes('instagram-feed') ? 'block' : 'none'}}> */}
            <iframe
                id='desktopInstagramFeedFrame'
                src={iframeURLs[0]}
                width='320px'
                height='567px'
                scrolling="no"
                frameborder={0}
                onLoad={() => setCountIframesLoaded(countIframesLoaded + 1)}
                style={{
                    border: 'none'
                }}></iframe>
        </div>
    )



    const InstagramPreviews = (
        <div style={{display: 'block'}}>
            <div style={{display: countIframesLoaded < 3 ? 'flex' : 'none', width: '100%', alignContent: 'center', justifyContent:'center'}}>
                <Skeleton variant="rectangular" width={320} height={567} />
            </div>
            <div style={{display: countIframesLoaded >= 3 ? 'flex' : 'none', width:'100%', alignContent: 'center', justifyContent:'center'}}>
                {instagramFeed}
                {instagramReels}
                {instagramStory}
            </div>
        </div>
    )

    return (
        <Stack alignItems='center' justifyContent='center' spacing={2}>
            {InstagramPreviews}

            <Box sx={{display:'block', width:'450px', margin:'auto'}}>
                <Box sx={{width:'100%', height:'100%', display:'flex', flexWrap:'wrap', alignItems:'center', justifyContent:'center'}}>
                        <SpotifyButton sx={{
                            margin:1,
                            display: formik.values.instagramPlacements.includes('instagram-feed') ? 'block' : 'none'}}
                            onClick={() => setPreviewIndex(1)}>Feed</SpotifyButton>
                        <SpotifyButton sx={{
                            margin:1,
                            display: formik.values.instagramPlacements.includes('instagram-stories') ? 'block' : 'none' }}
                            onClick={() => setPreviewIndex(0)}>Story</SpotifyButton>
                        <SpotifyButton sx={{
                            margin:1,
                            display: formik.values.instagramPlacements.includes('instagram-reels') ? 'block' : 'none'}}
                            onClick={() => setPreviewIndex(2)}>Reels</SpotifyButton>
                </Box>
            </Box>

        </Stack>

    );
}
