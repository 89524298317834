/*
 * Bryce Watson Spotify Card - Ads Manger Setup
 * Nov 2022
 */

import React, { useEffect, useRef } from "react";
import { useFormikContext, Field } from "formik";

import Checkbox from '@mui/material/Checkbox';

import { Stack } from "@mui/system";
import { Alert, FormControlLabel, FormGroup } from "@mui/material";

export default function CampaignNameCard() {
    const formik = useFormikContext();
    const ref = useRef();

    useEffect(()=>{
        formik.errors.instagramPlacements && ref.current.scrollIntoView();
    }, [formik.errors.instagramPlacements])

    return (
    // <FormCard label="Instagram Placements">
        <Stack spacing={1}>
        <FormGroup>
            <Field
                as={FormControlLabel}
                type="checkbox"
                name='instagramPlacements'
                value={'instagram-feed'}
                control={<Checkbox checked={formik.values.instagramPlacements.includes('instagram-feed')}/>}
                label="Instagram Feed"
            />
            <Field
                as={FormControlLabel}
                type="checkbox"
                name='instagramPlacements'
                value={'instagram-stories'}
                control={<Checkbox checked={formik.values.instagramPlacements.includes('instagram-stories')}/>}
                label="Instagram Stories"
            />
            <Field
                as={FormControlLabel}
                type="checkbox"
                name='instagramPlacements'
                value={'instagram-reels'}
                control={<Checkbox checked={formik.values.instagramPlacements.includes('instagram-reels')}/>}
                label="Instagram Reels"
            />
        </FormGroup>
            <div ref={ref}>
                {formik.errors.instagramPlacements && <Alert variant='filled' severity="error">Select at least 1 Instagram Placement</Alert>}
            </div>
        </Stack>

    // </FormCard>
    );
}
