/*
 *  Bryce David Watson
 *  Lightweight Advertising
 */


import React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import AdsFormWrapper from './components/form/AdsFormWrapper';
import MobileContainer from './mobile/MobileContainer.react';
import { MobileDataProviderWrapper } from "./mobile/MobileDataProvider.react";
import DesktopContainer from "./desktop/DesktopContainer.react";
import { PreviewURLWrapper } from "./PreviewURLWrapper.react";


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

const BodyContainer = () => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
      <div id='body-container'>
          <ThemeProvider theme={darkTheme}>
              <MobileDataProviderWrapper>
              <CssBaseline/>
              <AdsFormWrapper>
                <PreviewURLWrapper>
                  {smallScreen ? <MobileContainer/> : <DesktopContainer/>}
                </PreviewURLWrapper>
              </AdsFormWrapper>
              </MobileDataProviderWrapper>
          </ThemeProvider>
        </div>
        );
}

export default BodyContainer;
