import '../../static/css/App.css';
import React from 'react';
import SuccessImg from '../../static/img/success-icon-10.png';
import SpotifyButton from '../../mobile/SpotifyButton.react';
import BrandedLogos from '../landing/BrandedLogos.react';
import { Stack } from '@mui/system';
import DesktopFooter from '../../desktop/DesktopFooter.react';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import STATIC_WEB_HOST from '../../util/StaticWebhost';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function SuccessPage() {
  return (
	<div style={{
        backgroundColor: '#191414',
        position:'absolute',
        left:0,
        right:0,
        top:0,
        bottom:0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow:'hidden',
    }}>
      <ThemeProvider theme={darkTheme}>
      <CssBaseline/>

      <Stack justifyContent='center' alignItems='center' spacing={4}>

      <BrandedLogos/>
		  <img src={SuccessImg} style={{maxWidth:'200px'}} alt='Success Checkmark'/>
        <h3>Thank you! Your order was successful!</h3>
        <p style={{width:'400px', textAlign:'center'}}>When your advertisement finishes running you will recieve and email detailing your ads performance.</p>
        <SpotifyButton href={`https://${STATIC_WEB_HOST}`} >Create Another</SpotifyButton>
        </Stack>

        <Box sx={{position: 'absolute', bottom:0, width:'100%', height:'30px', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <DesktopFooter/>
        </Box>
        </ThemeProvider>

	</div>

  );
}
export default SuccessPage;
