/*
 * Bryce Watson
 * Spotify - Instagram Advertising Project
 * September 2022
 */

import React, { useState } from "react";
import InstagramGlyph from '../../static/img/Instagram_Glyph_White.png';
import SpotifyLogoRGB from '../../static/img/Spotify_Logo_RGB_Green.png';


export default function BrandedLogos() {
    const [loadedInstagramLogo, setLoadedInstagramLogo] = useState(false);
    const [loadedSpotifyLogo, setLoadedSpotifyLogo] = useState(false);

    const InstagramLogo = (
        <div style={{display: loadedInstagramLogo ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center'}}>
            <img onLoad={()=>{setLoadedInstagramLogo(true)}} src={InstagramGlyph} alt="Instagram Logo" width='36px'/>
            <div style={{cursor:'default', fontSize: '18px', padding: 6, color: 'white',}}>INSTAGRAM</div>
        </div>
    )

    const SpotifyLogo = (
        <img onLoad={()=>setLoadedSpotifyLogo(true)} src={SpotifyLogoRGB} height='36px'/>
    )

    return (
        <div style={{width:'280px', height:'50px'}}>
            <div style={{display: loadedInstagramLogo && loadedSpotifyLogo ? 'flex' : 'none', justifyContent: 'space-evenly', alignItems: 'center', padding:4}}>
                {InstagramLogo}
                {SpotifyLogo}
            </div>
        </div>

    )
}
