import React from "react";

import { Box, Stack } from "@mui/system";

import BrandedLogos from "./BrandedLogos.react";
import SpotifyButton from "../../mobile/SpotifyButton.react";

const LandingPageContent = ({handelGetStarted}) => {
    return (
        <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} spacing={3}>
            <BrandedLogos/>
            <Box sx={{fontSize: '56px', color:'white', fontWeight:'bold', textAlign: 'center', whiteSpace: 'pre-line'}}>
                Promocards.io
            </Box>
             <div style={{width: '350px', color: 'white', fontWeight: 'bold', textAlign:'center'}}>
                Advertise your Spotify song on Instagram by creating a Spotify song card adverisement
            </div>
            <SpotifyButton marginX={1}  onClick={() => handelGetStarted()}>Get Started</SpotifyButton>
        </Stack>
    );
}

export default LandingPageContent;
