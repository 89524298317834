/*
 * Bryce Watson
 * Spotify - Instagram Advertising Project
 * September 2022
 */

import React from "react";
import Stack from '@mui/material/Stack';

import StartDateInput from '../components/form/StartDateInput.react';
import EndDateInput from "../components/form/EndDateInput.react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';



const MobileScheduleInput = () => {
    return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack marginTop={1} spacing={3}>
            <StartDateInput/>
            <EndDateInput/>
        </Stack>
    </LocalizationProvider>
    );
}

export default MobileScheduleInput;
