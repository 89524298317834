import React, { useContext} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { createTheme } from '@mui/material/styles';
import { Stack, ThemeProvider } from "@mui/system";
import MobileAdPreview from './MobileAdPreview.react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { GlobalContext } from "../AppGlobalWrapper.react";
import SpotifyLogo from '../static/img/Spotify_icon.png';


const theme = createTheme({
    palette: {
        primary: {
        main: '#FAFAFA',
        },
        secondary: {
        main: '#f44336',
        },
    },
});

const MobileHeader = () => {
    const [context, setContext] = useContext(GlobalContext);

    const homeButton = (
        <Stack alignItems={'center'}>
            <Button disableRipple={true} onClick={() => setContext({...context, page_index: 0})}>Home</Button>
            <div style={{
                height: '2px',
                width: '50px',
                display:'block',
                background: "linear-gradient(to right, #1DB954 50%, #282828 50%)",
                backgroundSize: '200% 100%',
                backgroundPosition: context.page_index === 0 ? 'left bottom' : 'right bottom',
                transition: 'all .5s ease-out'}}/>
        </Stack>
    )

    const selectButton = (
        <Stack alignItems={'center'}>
            <Button sx={{width:'100px'}} disableRipple={true} onClick={() => setContext({...context, page_index: 1})}> &#32;SELECT&#32;</Button>
            <div style={{
                height: '2px',
                width: '50px',
                display:'block',
                background: "linear-gradient(to right, #1DB954 50%, #282828 50%)",
                backgroundSize: '200% 100%',
                backgroundPosition: context.page_index === 1 ? 'left bottom' : 'right bottom',
                transition: 'all .5s ease-out'}}/>
        </Stack>
    )

    const customizeButton = (
        <Stack alignItems={'center'}>
            <Button sx={{width:'100px'}} disableRipple={true} onClick={() => setContext({...context, page_index: 2})}>CUSTOMIZE</Button>
            <div style={{
                height: '2px',
                width: '80px',
                display:'block',
                background: "linear-gradient(to right, #1DB954 50%, #282828 50%)",
                backgroundSize: '200% 100%',
                backgroundPosition: context.page_index === 2 ? 'left bottom' : 'right bottom',
                transition: 'all .5s ease-out'}}/>
        </Stack>
    )

    const checkoutButton = (
        <Stack alignItems={'center'}>
            <Button sx={{width:'100px'}} disableRipple={true} onClick={() => setContext({...context, page_index: 3})}>CHECKOUT</Button>
            <div style={{
                height: '2px',
                width: '70px',
                display:'block',
                background: "linear-gradient(to right, #1DB954 50%, #282828 50%)",
                backgroundSize: '200% 100%',
                backgroundPosition: context.page_index === 3 ? 'left bottom' : 'right bottom',
                transition: 'all .5s ease-out'}}/>
        </Stack>
    )


    return (
    <ThemeProvider theme={theme}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', backgroundColor: '#282828', top: 0, height:'55px', position: 'sticky', zIndex: 2}} paddingY={1}>
            <Box onClick={() => setContext({...context, page_index: 0})} style={{width:'25px', height:'25px'}} marginLeft='10px'>
                <img src={SpotifyLogo} width='25px'/>
            </Box>
             <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                {selectButton}
                <NavigateNextIcon/>
                {customizeButton}
                <NavigateNextIcon/>
                {checkoutButton}
            </Box>
            <Box sx={{width:'25px', height:'25px'}} marginRight='10px'/>
        </Box>
        <MobileAdPreview/>
    </ThemeProvider>);
}

export default MobileHeader;
