import React, { useContext } from "react";

import { Box, Stack } from "@mui/system";
import InstagramPlacementsCard from '../components/form/InstagramPlacementsCard.react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import MobileCampaignNameInput from './MobileCampaignNameInput.react';
import MobileScheduleInput from './MobileScheduleInput.react';
import BudgetInput from '../components/form/BudgetInput.react';

import SpotifyButton from "./SpotifyButton.react";
import { GlobalContext } from "../AppGlobalWrapper.react";



const MobileSetupPage = () => {

    const [context, setContext] = useContext(GlobalContext);

    return (
        <div>
            <Stack padding={1} paddingBottom={2} spacing={2} alignItems='center' justifyContent='center'>
                <Stepper orientation="vertical" nonLinear={true}>
                    <Step active key={'Campaign Name'}>
                        <StepLabel>Campaign Name</StepLabel>
                        <StepContent>
                            <MobileCampaignNameInput />
                        </StepContent>
                    </Step>
                    <Step active key={"Budget"}>
                        <StepLabel>Budget</StepLabel>
                        <StepContent marginTop={1}>
                            <BudgetInput />
                        </StepContent>
                    </Step>
                    <Step active key={"Schedule"}>
                        <StepLabel>Schedule</StepLabel>
                        <StepContent>
                            <MobileScheduleInput />
                        </StepContent>
                    </Step>
                    <Step active key={"Instagram Placements"}>
                        <StepLabel>Instagram Placements</StepLabel>
                        <StepContent>
                            <InstagramPlacementsCard />
                        </StepContent>
                    </Step>
                </Stepper>
                <Box paddingX={1} sx={{maxWidth:'510px', width:'100%'}}>
                    <SpotifyButton fullWidth onClick={() => setContext({ ...context, page_index: ++context.page_index })} variant="contained">
                        CHECKOUT
                    </SpotifyButton>
                </Box>
            </Stack>
        </div>
    );
}

export default MobileSetupPage;
