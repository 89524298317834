import React, { useState, createContext} from "react";

export const PreviewURLContext = createContext();

export const PreviewURLWrapper = ({children}) => {
    const INITIAL_STATE = {
        previewURL_map: new Map(),
    }

    const [context, setContext] = useState(INITIAL_STATE);

    return (
        <PreviewURLContext.Provider value={[context, setContext]}>
            {children}
        </PreviewURLContext.Provider>
    );
}
