import React, {useCallback, useContext} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { createTheme } from '@mui/material/styles';
import { Stack, ThemeProvider } from "@mui/system";
import { GlobalContext } from "../AppGlobalWrapper.react";
import SpotifyLogo from '../static/img/Spotify_icon.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useFormikContext } from "formik";
import { Grid } from "@mui/material";
import SpotifyButton from "../mobile/SpotifyButton.react";
import SpotifyButtonSmall from "../mobile/SpotifyButtonSmall.react";
import STATIC_WEB_HOST from "../util/StaticWebhost";


const theme = createTheme({
    palette: {
        primary: {
            main: '#FAFAFA',
        },
        secondary: {
            main: '#f44336',
        },
        action: {
            disabled: '#b8b8b8'
        }
    },
});

const DesktopHeader = () => {
    const [context, setContext] = useContext(GlobalContext);
    const formik = useFormikContext();

    const setDesktopIndex = useCallback((index) => setContext({...context, desktop_index: index}), [context]);

    const homeButton = (
        <Stack alignItems={'center'}>
            <Button size='large' disableRipple={true} onClick={() => setDesktopIndex(0)}>Home</Button>
            <div style={{
                height: '2px',
                width: '50px',
                display:'block',
                background: "linear-gradient(to right, #1DB954 50%, #121212 50%)",
                backgroundSize: '200% 100%',
                backgroundPosition: context.desktop_index === 0 ? 'left bottom' : 'right bottom',
                transition: 'all .5s ease-out'}}/>
        </Stack>
    )

    const customizeButton = (
        <Stack alignItems={'center'}>
            <Button size='large' disableRipple={true} onClick={() => setDesktopIndex(1)}>Customize</Button>
            <div style={{
                height: '2px',
                width: '80px',
                display:'block',
                background: "linear-gradient(to right, #1DB954 50%, #121212 50%)",
                backgroundSize: '200% 100%',
                backgroundPosition: context.desktop_index === 1 ? 'left bottom' : 'right bottom',
                transition: 'all .5s ease-out'}}/>
        </Stack>
    )

    const checkoutButton = (
        <Stack alignItems={'center'}>
            <Button size='large' disabled={!formik.isValid} disableRipple={true} onClick={() => setDesktopIndex(2)}>Checkout</Button>
            <div style={{
                height: '2px',
                width: '70px',
                display:'block',
                background: "linear-gradient(to right, #1DB954 50%, #121212 50%)",
                backgroundSize: '200% 100%',
                backgroundPosition: context.desktop_index === 2 ? 'left bottom' : 'right bottom',
                transition: 'all .5s ease-out'}}/>
        </Stack>
    )


    return (
    <ThemeProvider theme={theme}>
        <Grid container spacing={0} direction="row" justifyContent="center" padding={1} alignItems='center'>
            <Grid item xs={1}></Grid>
            <Grid item xs={9}>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                {homeButton}
                <NavigateNextIcon/>
                {customizeButton}
                <NavigateNextIcon/>
                {checkoutButton}
            </Box>
            </Grid>
            <Grid item xs={1}>
                <SpotifyButtonSmall onClick={()=>window.location = 'recommended'} fullWidth>New Music</SpotifyButtonSmall>
            </Grid>
        </Grid>

    </ThemeProvider>);
}

export default DesktopHeader;
