import React from "react";
import { useFormikContext, } from "formik";
import { Stack } from "@mui/material";
import Spotify_Cards_Webhost from "../../util/SpotifyCardsWebhost";

export default function ImageContainer() {
    const formik = useFormikContext();

    const mySpotifyID = formik.values.spotifyID ??  '5EOoMWIB9iK4ZpcSex9Ec7';

    return (
        <Stack spacing={3}>
            <img class='desktop' src={`https://${Spotify_Cards_Webhost}/api?id=` + mySpotifyID + '&orientation=portrait'} alt='noImg' style={{display:'block', margin:'auto'}} />
         </Stack>
    );
}
