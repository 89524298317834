import React from "react";
import { useFormikContext } from "formik";
import DateReviewComponent from './DateReviewComponent.react';

import FormCard from '../form/FormCard.react';
import Button from "@mui/material/Button";

import Stack from '@mui/material/Stack';
import { Box } from "@mui/material";

export default function CampaignNameCard({onEditCampaign}) {
    const formik = useFormikContext();

    const instagramPlacementsDispaly = formik.values.instagramPlacements.map(placement => {
        switch (placement) {
            case 'instagram-feed':
                return 'Feed';
            case 'instagram-reels':
                return 'Reels';
            case 'instagram-stories':
                return 'Stories';
            default:
                return null;
        }
    }).join(', ')

    const editButton = (
        <Button variant="contained" color="primary" onClick={onEditCampaign}>
            Edit Campaign
        </Button>
    )

    return (
    <Stack spacing={1}>
        <FormCard label={"Review Campaign"} button={null}>
        <Stack spacing={1}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Campaign Name:</div>
                <div>{formik.values.campaignName}</div>
            </Box>
            <DateReviewComponent/>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{formik.values.budgetType === 'daily' ? 'Daily Budget:' : 'Lifetime Budget:'}</div>
                <div>${parseFloat(formik.values.budget).toFixed(2)}</div>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Instagram Placements:</div>
                <div>{instagramPlacementsDispaly}</div>
            </Box>

        </Stack>
        </FormCard>
    </Stack>
    );
}
