/*
 * Bryce Watson
 * Spotify - Instagram Advertising Project
 * September 2022
 */

import React, { useContext } from "react";

import StripeCheckout from '../components/checkout/StripeCheckout.react';
import ReviewFormCard from '../components/checkout/ReviewFormCard.react';
import CostsCard from "../components/checkout/CostsCard.react";

import Box from "@mui/material/Box";
import { GlobalContext } from "../AppGlobalWrapper.react";
import AdPreview from "../components/preview/AdPreview.react";
import OverflowCenterContainer from "../util/OverflowCenterContainer.react";

 const DesktopCheckoutPage = () => {

    const [context, setContext] = useContext(GlobalContext);

    const formPageIndex = context.formPageIndex;
    const setFormPageIndex = (value) => {setContext({...context, formPageIndex: value})};

    return (
            <Box  sx={{width:'100%', height:'100%', display:'flex', justifyContent:'space-evenly', alignItems:'center'}} padding={2}>
                <Box  paddingBottom={4} sx={{height:'100%', width: '800px', position:'relative', overflowY:'auto', overflowX:'hidden'}}>
                    <OverflowCenterContainer>
                         <ReviewFormCard onEditCampaign={() => setFormPageIndex(0)}/>
                        <CostsCard/>
                        <StripeCheckout />
                    </OverflowCenterContainer>
                </Box>
                <Box sx={{height:'100%', position:'relative', overflowY:'auto', overflowX:'hidden'}}>
                    <OverflowCenterContainer>
                        <AdPreview/>
                    </OverflowCenterContainer>
                </Box>
            </Box>
    );

}

export default DesktopCheckoutPage;
