import './static/css/App.css';
import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Spotify_Cards_Webhost from './util/SpotifyCardsWebhost';
import SpotifyButton from './mobile/SpotifyButton.react';

import { trackCustom } from 'fbq'


function SongLandingPage() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [loaded, setLoaded] = useState(false);

    const mySpotifyID = searchParams.get('id') ?? '5EOoMWIB9iK4ZpcSex9Ec7';

    const SpotifyURL = `https://open.spotify.com/track/${mySpotifyID}`;

    const onClick = () => {
        trackCustom('SpotifyLinkClick', {'SpotifyID': mySpotifyID});
        window.open(SpotifyURL, '_self');
    }


    return (
        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#282828', textAlign:'center'}}>
            <Stack spacing={2} sx={{display: loaded ? 'block' : 'none', maxWidth: '400px', width: '80%'}}>
                <img onClick={onClick} onLoad={()=>setLoaded(true)} src={`https://${Spotify_Cards_Webhost}/api?id=` + mySpotifyID + '&orientation=portrait_wide'} alt='noImg' style={{width:'100%' , display:'block', margin:'auto'}} />
                <SpotifyButton fullWidth onClick={onClick}>Listen on Spotify</SpotifyButton>
            </Stack>
        </Box>

    );
}

export default SongLandingPage;
