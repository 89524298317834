import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";

const SpotifyButton = styled(Button)(({ theme }) => ({
  color: 'white',
  borderRadius: 50,
  fontWeight: 'bold',
  fontSize: '18px',
  backgroundColor: '#1DB954',
  "&:hover": {
    backgroundColor: '#1DB954'
  },
  minWidth: '200px'
}));

export default SpotifyButton;
