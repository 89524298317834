import React, { useContext } from "react";

import { Stack } from "@mui/system";
import StripeCheckout from '../components/checkout/StripeCheckout.react';
import ReviewFormCard from '../components/checkout/ReviewFormCard.react';
import CostsCard from "../components/checkout/CostsCard.react";
import { GlobalContext } from "../AppGlobalWrapper.react";


const MobileCheckoutPage = () => {

    const [context, setContext] = useContext(GlobalContext);

    return (
        <div>
            <Stack padding={1} paddingX={2} paddingBottom={6} spacing={1}>
                <ReviewFormCard onEditCampaign={() => setContext({...context, page_index: 2})}/>
                <CostsCard/>
                <StripeCheckout/>
            </Stack>
        </div>
    );
}

export default MobileCheckoutPage;
