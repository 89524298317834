import React from "react";
import { useFormikContext, } from "formik";
import TextField from "@mui/material/TextField";

import { DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers';

import { Stack } from "@mui/material";

export default function StartDateInput() {
    const formik = useFormikContext();

    const handleChange = (value) => formik.setFieldValue('startDate', value)

    return (
        <Stack>
            <Stack direction='row' spacing={1}>
                <DatePicker
                    size='small'
                    name="startDate"
                    id="startDate"
                    label="Start Date"
                    onChange={(val) => handleChange(val.toDate())}
                    value={formik.values.startDate}
                    renderInput={(params) => (
                        <TextField  sx={{width: '50%'}} {...params} helperText={params?.inputProps?.placeholder} variant='outlined'/>
                    )}
                    disablePast
                />
                <TimePicker
                    size='small'
                    label="Time"
                    value={formik.values.startDate}
                    onChange={(val) => handleChange(val.toDate())}
                    renderInput={(params) => <TextField  sx={{width: '50%'}} {...params} variant='outlined'/>}
                />
            </Stack>
        </Stack>

    );
}
