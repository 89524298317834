import '../static/css/App.css';
import React from 'react';
import TermsAndConditonsHTML from './TermsAndConditionsHTML.react';
import { Box } from '@mui/material';

function LegalPage() {
  return (
	<Box paddingX={4} sx={{maxWidth:'800px', margin:'auto', lineHeight:1.6, fontSize:'14px'}}>
      <TermsAndConditonsHTML />
    </Box>

  );
}

export default LegalPage;
