import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";

const AmazonButtonSmall = styled(Button)(({ theme }) => ({
  color: 'white',
  borderRadius: 4,
  fontWeight: 'bold',
  fontSize: '12px',
  backgroundColor: '#FF9900',
  "&:hover": {
    backgroundColor: '#FF9900'
  },
  minWidth: '100px'
}));

export default AmazonButtonSmall;
